<script>
  import Loading from './Loading.svelte';

  export let type = 'button';

  export let uppercase = false;
  export let secondary = false;
  export let outlined = false;
  export let disabled = false;
  export let small = false;
  export let large = false;
  export let loading = false;
  export let floating = false
  export let wiggle = false;
</script>

<style>
  .button {
    background-color: #f57224;
    border: none;
    color: #fff;
    font-size: 14px;
    padding: 10px 22px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    display: inline-block;
    font-family: Poppins;
  }
  .button.small {
    padding: 8px 18px;
    font-size: 11px;
  }
  .button.large {
    padding: 12px 27px;
    font-size: 16px;
  }
  .button:hover {
    background-color: #e96417;
  }
  .button.outlined {
    background: none;
    color: #f57224;
    border: 1px solid #f57224;
  }
  .button.secondary.outlined {
    background: none;
    color: #636262;
    border: 1px solid #636262;
  }
  .button.outlined:hover {
    background: none;
    border: 1px solid #e96417;
  }
  .button.uppercase {
    text-transform: uppercase;
  }
  .button.secondary {
    background-color: #636262;
  }
  .button.secondary:hover {
    background-color: #8c8c8c;
  }
  .button.secondary.outlined:hover {
    background: none;
    color: #8c8c8c;
    border: 1px solid #8c8c8c;
  }
  .button:disabled,
  .button:disabled:hover {
    cursor: auto;
    color: #f3f1f1;
    background-color: #cf8b64;
  }
  .button.secondary:disabled,
  .button.secondary:disabled:hover {
    background-color: #888888;
  }
  .button.floating {
    position: fixed;
    bottom: 15px;
    right: 20px;
    z-index: 10;
  }

  .wiggle {
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    animation: btnWiggle 5s infinite;
  }

  @media (max-width: 600px) {
    .button.floating {
      right: 14px;
    }
  }


  :global(.loading) {
    margin-right: 0.75em;
  }

  /* animation */
@-webkit-keyframes btnWiggle {
	0% {-webkit-transform: rotate(0deg);}
  4% {-webkit-transform: rotate(-2.5deg);}
  8% {-webkit-transform: rotate(5deg);}
  12% {-webkit-transform: rotate(-2.5deg);}
  16% {-webkit-transform: rotate(0deg);}
}
@-o-keyframes btnWiggle {
	0% {-webkit-transform: rotate(0deg);}
  4% {-webkit-transform: rotate(-2.5deg);}
  8% {-webkit-transform: rotate(5deg);}
  12% {-webkit-transform: rotate(-2.5deg);}
  16% {-webkit-transform: rotate(0deg);}
}
@keyframes btnWiggle {
	0% {-webkit-transform: rotate(0deg);}
  4% {-webkit-transform: rotate(-2.5deg);}
  8% {-webkit-transform: rotate(5deg);}
  12% {-webkit-transform: rotate(-2.5deg);}
  16% {-webkit-transform: rotate(0deg);}
}
  
</style>

<button
  {type}
  class="button"
  class:uppercase
  class:secondary
  class:outlined
  class:small
  class:large
  class:floating
  class:wiggle
  on:click
  {disabled}>
  {#if loading}
    <Loading class="loading" />
  {/if}
  <slot />
</button>
